// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendar-js": () => import("../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-camps-js": () => import("../src/pages/camps.js" /* webpackChunkName: "component---src-pages-camps-js" */),
  "component---src-pages-coaches-js": () => import("../src/pages/coaches.js" /* webpackChunkName: "component---src-pages-coaches-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parents-js": () => import("../src/pages/parents.js" /* webpackChunkName: "component---src-pages-parents-js" */),
  "component---src-pages-players-js": () => import("../src/pages/players.js" /* webpackChunkName: "component---src-pages-players-js" */),
  "component---src-pages-registration-js": () => import("../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-teams-js": () => import("../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */)
}

