module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cavalry Basketball","short_name":"Cavalry","start_url":"/","background_color":"#888888","theme_color":"#a9140d","display":"minimal-ui","icon":"src/images/cavalry-logo.png","orientation":"portrait"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-155200523-1","anonymize":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
